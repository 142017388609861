var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "my-bookings" } },
    [
      _c("HeaderNavigation", { attrs: { title: "mes réservations en cours" } }),
      _c(
        "b-container",
        { staticClass: "main-container mt-3" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button-group",
                    { staticClass: "switch" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "driver",
                          attrs: {
                            variant: _vm.isDriver ? "dark" : "light",
                            disabled: _vm.disableDriver
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.isDriver = true
                            }
                          }
                        },
                        [_vm._v("CONDUCTEUR")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "provider",
                          attrs: {
                            variant: !_vm.isDriver ? "dark" : "light",
                            disabled: _vm.disableProvider
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.isDriver = false
                            }
                          }
                        },
                        [_vm._v("HÔTE")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDriver,
                      expression: "isDriver"
                    }
                  ]
                },
                [
                  _vm._l(_vm.bookings.driver, function(dBooking) {
                    return _c("DriverBookingDetails", {
                      key: dBooking.id,
                      staticClass: "booking",
                      attrs: { booking: dBooking }
                    })
                  }),
                  !_vm.bookings.driver.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("img", {
                          staticClass: "text-evenir-content-decorator",
                          attrs: {
                            src: require("@/assets/svg/small-vehicle.svg"),
                            width: "35"
                          }
                        }),
                        _c("p", { staticClass: "text-evenir-content-p" }, [
                          _vm._v(
                            " Vous n'avez pas de réservation de point de recharge en cours. "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c(
                "b-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isDriver,
                      expression: "!isDriver"
                    }
                  ]
                },
                [
                  _vm._l(_vm.bookings.provider, function(pBooking) {
                    return _c("ProviderBookingDetails", {
                      key: pBooking.id,
                      staticClass: "booking",
                      attrs: { booking: pBooking }
                    })
                  }),
                  !_vm.bookings.provider.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("img", {
                          staticClass: "text-evenir-content-decorator",
                          attrs: {
                            src: require("@/assets/svg/small-charging-point.svg"),
                            width: "11"
                          }
                        }),
                        _c("p", { staticClass: "text-evenir-content-p" }, [
                          _vm._v(
                            " Vous n'avez pas de réservation en cours sur vos points de recharge actuellement. "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }