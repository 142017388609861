<template>
  <div class="cp-details">
    <b-row>
      <b-col cols="6">
        <b-form-group label="Type et Puissance">
          <span class="text-uppercase"
            >{{ chargingPoint.type }} - {{ chargingPoint.power }} kVA</span
          >
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Visibilité">
          <span class="text-uppercase">{{
            chargingPoint.private ? 'Mes invités' : 'Tout le monde'
          }}</span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Accès">
          <span class="text-uppercase">{{
            chargingPoint.context.accessibility
          }}</span>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Démarrage">
          <span class="text-uppercase">{{
            chargingPoint.context.parkability
          }}</span>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="">
        <b-form-group label="Détails Complémentaires">
          <span class="text-uppercase">{{
            chargingPoint.context.details
          }}</span>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'charging-point-details',
  props: {
    chargingPoint: { type: Object, required: true }
  },
  computed: {
    price() {
      return this.chargingPoint.price ? this.chargingPoint.price : 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.cp-details {
  font-weight: $font-weight-bold;
  font-size: 9px;
  color: $dark;

  fieldset {
    margin-bottom: 0;

    /deep/ legend {
      color: #a0a0a0;
      padding-bottom: 0;
      font-size: 9px !important;
      text-transform: uppercase;
    }
  }

  .price {
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;

    &::before {
      display: inline-block;
      padding: 0px 5px 0px 0px;
      vertical-align: -2px;
      content: url('../../assets/img/icon-yellow-plus-sign.png');
    }
  }
}
</style>
