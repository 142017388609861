<template>
  <div id="my-bookings">
    <HeaderNavigation title="mes réservations en cours" />
    <b-container class="main-container mt-3">
      <b-row>
        <b-col>
          <b-button-group class="switch">
            <b-button
              :variant="isDriver ? 'dark' : 'light'"
              class="driver"
              :disabled="disableDriver"
              @click.prevent="isDriver = true"
              >CONDUCTEUR</b-button
            >
            <b-button
              :variant="!isDriver ? 'dark' : 'light'"
              class="provider"
              :disabled="disableProvider"
              @click.prevent="isDriver = false"
              >HÔTE</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-show="isDriver">
          <DriverBookingDetails
            v-for="dBooking in bookings.driver"
            :key="dBooking.id"
            :booking="dBooking"
            class="booking"
          />
          <div v-if="!bookings.driver.length" class="text-center">
            <img
              src="@/assets/svg/small-vehicle.svg"
              width="35"
              class="text-evenir-content-decorator"
            />
            <p class="text-evenir-content-p">
              Vous n'avez pas de réservation de point de recharge en cours.
            </p>
          </div>
        </b-col>
        <b-col v-show="!isDriver">
          <ProviderBookingDetails
            v-for="pBooking in bookings.provider"
            :key="pBooking.id"
            :booking="pBooking"
            class="booking"
          />
          <div v-if="!bookings.provider.length" class="text-center">
            <img
              src="@/assets/svg/small-charging-point.svg"
              width="11"
              class="text-evenir-content-decorator"
            />
            <p class="text-evenir-content-p">
              Vous n'avez pas de réservation en cours sur vos points de recharge
              actuellement.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import DriverBookingDetails from '@/components/Booking/DriverBookingDetails.vue'
import ProviderBookingDetails from '@/components/Booking/ProviderBookingDetails.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'my-bookings',
  components: {
    HeaderNavigation,
    DriverBookingDetails,
    ProviderBookingDetails
  },
  data() {
    return {
      isDriver: true
    }
  },
  computed: {
    ...mapGetters(['hasVehicles', 'hasChargingPoints', 'hasNothing']),
    disableDriver() {
      return !this.hasVehicles
    },
    disableProvider() {
      return !this.hasChargingPoints
    },
    bookings() {
      return {
        driver: this.$store.getters.driverBookings || [],
        provider: this.$store.getters.providerBookings || []
      }
    }
  },
  mounted() {
    this.isDriver = this.hasVehicles || this.hasNothing
    this.loadBookings({ api: this.$apiService, refresh: true }).catch(err =>
      console.error('[App] Uncaught error while loading bookings: ', err)
    )
  },
  methods: {
    ...mapActions(['loadBookings'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-bookings {
  .switch {
    background-color: #f3f3f3;
    border-radius: 10px;
    width: 100%;
    height: 48px;

    button {
      font-weight: $font-weight-bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;

      &.btn-dark {
        &.driver {
          color: #00c2d8;
        }

        &.provider {
          color: $provider-yellow;
        }
      }

      &.btn-light {
        background-color: transparent;
        box-shadow: none;
        color: #989898;
      }

      &.btn-dark:hover {
        background-color: $dark;
      }
    }
  }

  .booking {
    margin-top: 20px;
  }
}
</style>
