var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cp-details" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "Type et Puissance" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(
                    _vm._s(_vm.chargingPoint.type) +
                      " - " +
                      _vm._s(_vm.chargingPoint.power) +
                      " kVA"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "Visibilité" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(
                    _vm._s(
                      _vm.chargingPoint.private
                        ? "Mes invités"
                        : "Tout le monde"
                    )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "Accès" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.chargingPoint.context.accessibility))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-group", { attrs: { label: "Démarrage" } }, [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.chargingPoint.context.parkability))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Détails Complémentaires" } },
                [
                  _c("span", { staticClass: "text-uppercase" }, [
                    _vm._v(_vm._s(_vm.chargingPoint.context.details))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }