var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "charging-point-booking-details text-uppercase" },
    [
      _c(
        "b-media",
        { staticClass: "top", attrs: { "no-body": "" } },
        [
          _c(
            "b-media-aside",
            { staticClass: "side-image align-self-stretch" },
            [_c("VehicleIcon")],
            1
          ),
          _c(
            "b-media-body",
            [
              _c("span", { staticClass: "title-headline d-flex" }, [
                _vm._v(_vm._s(_vm.booking.vehicle.name))
              ]),
              _c("p", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.booking.vehicle.username))
              ]),
              _c("p", { staticClass: "info" }, [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.delay) } })
              ]),
              _c("BookingProgress", {
                ref: "progress",
                attrs: { time: _vm.booking.time }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-media",
        { staticClass: "bottom", attrs: { "no-body": "" } },
        [
          _c(
            "b-media-aside",
            { staticClass: "side-image align-self-stretch" },
            [_c("ChargingPointIcon")],
            1
          ),
          _c(
            "b-media-body",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("span", { staticClass: "title-headline d-flex" }, [
                      _vm._v(
                        "Point de recharge #" +
                          _vm._s(_vm.booking.chargingPoint.id)
                      )
                    ]),
                    _c("p", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.booking.chargingPoint.address))
                    ]),
                    _c("p", { staticClass: "price" }, [
                      _vm._v(_vm._s(_vm.booking.basePrice) + "€")
                    ])
                  ])
                ],
                1
              ),
              false
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "light" }
                            },
                            [_vm._v("Annuler la réservation")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }