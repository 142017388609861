var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "booking-progress" },
    [
      _c("b-progress", {
        attrs: { value: _vm.progressValue, max: _vm.progressMax, height: "7px" }
      }),
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.getTimeString(_vm.time.start)))]),
        _c("span", [_vm._v(_vm._s(_vm.getTimeString(_vm.time.end)))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }