<template>
  <div class="vehicle-booking-details text-uppercase">
    <b-media class="top" no-body>
      <b-media-aside class="side-image align-self-stretch">
        <VehicleIcon />
      </b-media-aside>
      <b-media-body>
        <b-row>
          <b-col>
            <span class="title-headline d-flex">{{
              booking.vehicle.name
            }}</span>
            <p class="info">{{ booking.vehicle.username }}</p>
            <p>Recharge pour environ {{ booking.estimatedDistance }} km</p>
            <p class="price">{{ booking.price }}&euro;</p>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col class="text-right">
            <b-button class="text-uppercase" variant="light" disabled
              >Informations</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              v-b-modal="`modalSubject_${booking.id}`"
              class="text-uppercase"
              variant="light"
              >Signaler un problème</b-button
            >
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col class="text-right">
            <b-button class="text-uppercase" variant="light" disabled
              >Annuler la réservation</b-button
            >
          </b-col>
        </b-row>
      </b-media-body>
    </b-media>
    <b-media class="bottom" no-body>
      <b-media-aside class="side-image align-self-stretch">
        <ChargingPointIcon />
      </b-media-aside>
      <b-media-body>
        <span class="title-headline text-uppercase d-flex"
          >Point de recharge #{{ booking.chargingPoint.id }}</span
        >
        <p class="info">{{ booking.chargingPoint.address }}</p>
        <p class="info"><span v-html="delay"></span></p>
        <BookingProgress ref="progress" :time="booking.time" />
        <ChargingPointDetails :chargingPoint="cpDetails" class="my-3" />
        <b-row class="picture-row flex-nowrap" no-gutters>
          <b-col cols="4">
            <b-img-lazy
              v-for="(photo, idx) in photos"
              :key="idx"
              :src="photo.url"
              class="thumb"
            />
          </b-col>
        </b-row>
      </b-media-body>
    </b-media>
    <b-modal
      :id="`modalSubject_${booking.id}`"
      title="Signaler un problème lié à la recharge"
      centered
      no-stacking
    >
      <b-form>
        <b-form-row>
          <b-col>
            Vous avez rencontré un problème et n’avez pas pu utiliser votre
            recharge réservée.<br />
            Nos réponses peuvent vous aider, sinon contactez-nous.
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-select v-model="question" :options="subjectOptions">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  Choisissez votre sujet
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-form-row>
      </b-form>
      <template v-slot:modal-footer>
        <b-button
          v-b-modal="`modalAnswer_${booking.id}`"
          variant="dark"
          :disabled="!question"
        >
          VALIDER
        </b-button>
      </template>
    </b-modal>
    <b-modal
      :id="`modalAnswer_${booking.id}`"
      ref="modalAnswer"
      :title="!!question ? question.title : ''"
      @ok="sendComplaint"
      @cancel="$router.push(homeRoute)"
      centered
    >
      <b-row>
        <b-col>
          {{ !!question ? question.answer : '' }}
        </b-col>
      </b-row>
      <b-form v-if="question && question.requiresInput">
        <b-form-row>
          <b-col>
            <b-textarea
              v-model="message"
              placeholder="Posez votre question ici."
              maxlength="300"
              rows="3"
              required
              trim
            />
            {{ message.length }} / 300
          </b-col>
        </b-form-row>
      </b-form>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="dark" @click="cancel()">CA M'A AIDE</b-button>
        <b-button
          v-show="question.sendComplaintCallback"
          v-loader.disable="sendingComplaint"
          variant="dark"
          @click="ok()"
        >
          ENVOYER MA REQUÊTE
        </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="modalEmailSent"
      title="Votre requête a bien été envoyée"
      ok-title="RETOUR ACCUEIL"
      ok-variant="dark"
      @ok="$router.push(homeRoute)"
      ok-only
      centered
    >
      <b-row>
        <b-col>
          L'équipe EVENIR prend connaissance de votre réclamation et vous fait
          un retour directement sur votre boite mail dans les plus bref délai.
          Veuillez accepter nos sincères excuses pour la gêne occasionnée.
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BookingProgress from '@/components/Booking/BookingProgress.vue'
import ChargingPointDetails from '@/components/Booking/ChargingPointDetails.vue'
import VehicleIcon from '@/components/svg/VehicleIcon.vue'
import ChargingPointIcon from '@/components/svg/ChargingPointIcon.vue'
import {
  ComplaintKind,
  BookingComplaintSubject
} from '@/assets/js/Constants.js'
import * as FAQ from '@/utils/faq-utils.js'
import { Routes } from '@/router.js'

export default {
  name: 'vehicle-booking-details',
  components: {
    BookingProgress,
    ChargingPointDetails,
    VehicleIcon,
    ChargingPointIcon
  },
  props: {
    booking: { type: Object, required: true }
  },
  data() {
    return {
      delay: '&nbsp;',
      cpDetails: {
        context: {}
      },
      photos: [],
      question: null,
      questions: FAQ.Questions,
      sendingComplaint: false,
      message: ''
    }
  },
  computed: {
    homeRoute() {
      return Routes.HOME
    },
    subjectOptions() {
      return this.questions
        .filter(q => q.context == FAQ.FaqContext.Booking)
        .map(e => ({ value: e, text: e.title }))
    }
  },
  mounted() {
    this.delay = this.$refs.progress.delay() || '&nbsp;'

    this.$apiService
      .getUserChargingPointAsync(
        this.booking.chargingPoint.ownerId,
        this.booking.chargingPoint.id
      )
      .then(cp => {
        this.cpDetails = cp
      })

    this.$apiService
      .getChargingPointPhotosAsync(this.booking.chargingPoint.id)
      .then(photos =>
        Promise.all(
          photos.map(p =>
            this.$apiService
              .getChargingPointPhotoContentDataURLAsync(p.resourceId, p.fileId)
              .then(url => {
                p.url = url

                return p
              })
          )
        )
      )
      .then(photos => (this.photos = photos || []))
  },
  methods: {
    ...mapActions(['createComplaint', 'loadChargingPointPhotos']),
    sendComplaint(evt) {
      evt.preventDefault()
      // this.createComplaint({
      //   api: this.apiService,
      //   bkId: 'GET BOOKING ID',
      //   reason: this.reason
      // })
      this.sendingComplaint = true
      this[this.question.sendComplaintCallback]()
        .then(() =>
          this.$nextTick(() => {
            this.$refs.modalAnswer.hide()
            this.$refs.modalEmailSent.show()
          })
        )
        .catch(err => console.error('Could not send complaint: ', err))
        .finally(() => (this.sendingComplaint = false))
    },
    getBookingDetails() {
      const { userId, username } = this.booking.vehicle

      return {
        id: this.booking.id,
        ...this.booking.time,
        userId,
        username,
        chargingPointId: this.booking.chargingPoint.id,
        ownerId: this.booking.chargingPoint.ownerId
      }
    },
    [FAQ.CANNOT_FIND_CP]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.BookingComplaint,
        BookingComplaintSubject.CannotFindCP,
        { booking: this.getBookingDetails() }
      )
    },
    [FAQ.CANNOT_ACCESS_CP]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.BookingComplaint,
        BookingComplaintSubject.CannotAccessCP,
        { booking: this.getBookingDetails() }
      )
    },
    [FAQ.CP_BUSY]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.BookingComplaint,
        BookingComplaintSubject.CPBusy,
        { booking: this.getBookingDetails() }
      )
    },
    [FAQ.CP_NOT_WORKING]() {
      return this.$apiService.postUserComplaintAsync(
        ComplaintKind.BookingComplaint,
        BookingComplaintSubject.CPNotWorking,
        { booking: this.getBookingDetails() }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.vehicle-booking-details {
  border-radius: 10px;

  .media {
    align-items: normal;

    div.side-image {
      width: 60px;
      justify-content: center;

      svg {
        margin: auto;
        stroke: #fff;
      }
    }

    &.top {
      background-color: #f8f8f8;
      border-radius: 10px 10px 0 0;

      div.side-image {
        background-color: $driver-blue;
        border-radius: 10px 0 0 0px;
      }

      .media-body {
        margin-bottom: 24px;

        button.btn {
          width: 187px;
          height: 48px;
          font-size: 13px;
          font-weight: $font-weight-bold;
          letter-spacing: 0.65px;
          line-height: 12px;
        }
      }
    }

    &.bottom {
      background-color: #ebebeb;
      border-radius: 0 0 10px 10px;

      div.side-image {
        background-color: $dark;
        border-radius: 0 0 0 10px;
      }

      .media-body {
        margin-bottom: 10px;

        .picture-row {
          overflow-x: scroll;

          img.thumb {
            width: 88px;
            height: 92px;
            object-fit: cover;
          }
        }
      }
    }

    .media-body {
      margin: 15px;

      .title-headline {
        height: auto;
      }

      .title-headline,
      .price {
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        color: $dark;
      }

      .info {
        font-weight: $font-weight-bold;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0;
        color: #a0a0a0;
        margin-bottom: 0;
      }

      .price {
        line-height: 48px;
        &::before {
          display: inline-block;
          padding: 0px 5px 0px 0px;
          vertical-align: -2px;
          content: url('../../assets/img/icon-blue-minus-sign.png');
        }
      }
    }
  }
}
</style>
