var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-booking-details text-uppercase" },
    [
      _c(
        "b-media",
        { staticClass: "top", attrs: { "no-body": "" } },
        [
          _c(
            "b-media-aside",
            { staticClass: "side-image align-self-stretch" },
            [_c("VehicleIcon")],
            1
          ),
          _c(
            "b-media-body",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("span", { staticClass: "title-headline d-flex" }, [
                      _vm._v(_vm._s(_vm.booking.vehicle.name))
                    ]),
                    _c("p", { staticClass: "info" }, [
                      _vm._v(_vm._s(_vm.booking.vehicle.username))
                    ]),
                    _c("p", [
                      _vm._v(
                        "Recharge pour environ " +
                          _vm._s(_vm.booking.estimatedDistance) +
                          " km"
                      )
                    ]),
                    _c("p", { staticClass: "price" }, [
                      _vm._v(_vm._s(_vm.booking.price) + "€")
                    ])
                  ])
                ],
                1
              ),
              false
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "light", disabled: "" }
                            },
                            [_vm._v("Informations")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: "modalSubject_" + _vm.booking.id,
                              expression: "`modalSubject_${booking.id}`"
                            }
                          ],
                          staticClass: "text-uppercase",
                          attrs: { variant: "light" }
                        },
                        [_vm._v("Signaler un problème")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              false
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "light", disabled: "" }
                            },
                            [_vm._v("Annuler la réservation")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-media",
        { staticClass: "bottom", attrs: { "no-body": "" } },
        [
          _c(
            "b-media-aside",
            { staticClass: "side-image align-self-stretch" },
            [_c("ChargingPointIcon")],
            1
          ),
          _c(
            "b-media-body",
            [
              _c(
                "span",
                { staticClass: "title-headline text-uppercase d-flex" },
                [
                  _vm._v(
                    "Point de recharge #" + _vm._s(_vm.booking.chargingPoint.id)
                  )
                ]
              ),
              _c("p", { staticClass: "info" }, [
                _vm._v(_vm._s(_vm.booking.chargingPoint.address))
              ]),
              _c("p", { staticClass: "info" }, [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.delay) } })
              ]),
              _c("BookingProgress", {
                ref: "progress",
                attrs: { time: _vm.booking.time }
              }),
              _c("ChargingPointDetails", {
                staticClass: "my-3",
                attrs: { chargingPoint: _vm.cpDetails }
              }),
              _c(
                "b-row",
                {
                  staticClass: "picture-row flex-nowrap",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    _vm._l(_vm.photos, function(photo, idx) {
                      return _c("b-img-lazy", {
                        key: idx,
                        staticClass: "thumb",
                        attrs: { src: photo.url }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modalSubject_" + _vm.booking.id,
            title: "Signaler un problème lié à la recharge",
            centered: "",
            "no-stacking": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: "modalAnswer_" + _vm.booking.id,
                          expression: "`modalAnswer_${booking.id}`"
                        }
                      ],
                      attrs: { variant: "dark", disabled: !_vm.question }
                    },
                    [_vm._v(" VALIDER ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-form-row",
                [
                  _c("b-col", [
                    _vm._v(
                      " Vous avez rencontré un problème et n’avez pas pu utiliser votre recharge réservée."
                    ),
                    _c("br"),
                    _vm._v(
                      " Nos réponses peuvent vous aider, sinon contactez-nous. "
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.subjectOptions },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null, disabled: "" } },
                                  [_vm._v(" Choisissez votre sujet ")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.question,
                          callback: function($$v) {
                            _vm.question = $$v
                          },
                          expression: "question"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modalAnswer",
          attrs: {
            id: "modalAnswer_" + _vm.booking.id,
            title: !!_vm.question ? _vm.question.title : "",
            centered: ""
          },
          on: {
            ok: _vm.sendComplaint,
            cancel: function($event) {
              return _vm.$router.push(_vm.homeRoute)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function(ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "dark" },
                      on: {
                        click: function($event) {
                          return cancel()
                        }
                      }
                    },
                    [_vm._v("CA M'A AIDE")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.question.sendComplaintCallback,
                          expression: "question.sendComplaintCallback"
                        },
                        {
                          name: "loader",
                          rawName: "v-loader.disable",
                          value: _vm.sendingComplaint,
                          expression: "sendingComplaint",
                          modifiers: { disable: true }
                        }
                      ],
                      attrs: { variant: "dark" },
                      on: {
                        click: function($event) {
                          return ok()
                        }
                      }
                    },
                    [_vm._v(" ENVOYER MA REQUÊTE ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(
                  " " + _vm._s(!!_vm.question ? _vm.question.answer : "") + " "
                )
              ])
            ],
            1
          ),
          _vm.question && _vm.question.requiresInput
            ? _c(
                "b-form",
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-textarea", {
                            attrs: {
                              placeholder: "Posez votre question ici.",
                              maxlength: "300",
                              rows: "3",
                              required: "",
                              trim: ""
                            },
                            model: {
                              value: _vm.message,
                              callback: function($$v) {
                                _vm.message = $$v
                              },
                              expression: "message"
                            }
                          }),
                          _vm._v(" " + _vm._s(_vm.message.length) + " / 300 ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modalEmailSent",
          attrs: {
            title: "Votre requête a bien été envoyée",
            "ok-title": "RETOUR ACCUEIL",
            "ok-variant": "dark",
            "ok-only": "",
            centered: ""
          },
          on: {
            ok: function($event) {
              return _vm.$router.push(_vm.homeRoute)
            }
          }
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(
                  " L'équipe EVENIR prend connaissance de votre réclamation et vous fait un retour directement sur votre boite mail dans les plus bref délai. Veuillez accepter nos sincères excuses pour la gêne occasionnée. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }