<template>
  <div class="charging-point-booking-details text-uppercase">
    <b-media class="top" no-body>
      <b-media-aside class="side-image align-self-stretch">
        <VehicleIcon />
      </b-media-aside>
      <b-media-body>
        <span class="title-headline d-flex">{{ booking.vehicle.name }}</span>
        <p class="info">{{ booking.vehicle.username }}</p>
        <p class="info"><span v-html="delay"></span></p>
        <BookingProgress ref="progress" :time="booking.time" />
      </b-media-body>
    </b-media>
    <b-media class="bottom" no-body>
      <b-media-aside class="side-image align-self-stretch">
        <ChargingPointIcon />
      </b-media-aside>
      <b-media-body>
        <b-row>
          <b-col>
            <span class="title-headline d-flex"
              >Point de recharge #{{ booking.chargingPoint.id }}</span
            >
            <p class="info">{{ booking.chargingPoint.address }}</p>
            <p class="price">{{ booking.basePrice }}&euro;</p>
          </b-col>
        </b-row>
        <b-row v-if="false">
          <b-col class="text-right">
            <b-button class="text-uppercase" variant="light"
              >Annuler la réservation</b-button
            >
          </b-col>
        </b-row>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import BookingProgress from '@/components/Booking/BookingProgress.vue'
import ChargingPointIcon from '@/components/svg/ChargingPointIcon.vue'
import VehicleIcon from '@/components/svg/VehicleIcon.vue'

export default {
  name: 'charging-point-booking-details',
  components: { BookingProgress, ChargingPointIcon, VehicleIcon },
  props: {
    booking: { type: Object, required: true }
  },
  data() {
    return {
      delay: '&nbsp;'
    }
  },
  mounted() {
    this.delay = this.$refs.progress.delay() || '&nbsp;'
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.charging-point-booking-details {
  border-radius: 10px;

  .media {
    align-items: normal;

    div.side-image {
      width: 60px;
      justify-content: center;

      svg {
        margin: auto;
        stroke: #fff;
      }
    }

    &.top {
      background-color: #ebebeb;
      border-radius: 10px 10px 0 0;

      div.side-image {
        background-color: $dark;
        border-radius: 10px 0 0 0px;
      }

      .media-body {
        margin-bottom: 10px;
      }
    }

    &.bottom {
      background-color: #f8f8f8;
      border-radius: 0 0 10px 10px;

      div.side-image {
        background-color: $provider-yellow;
        border-radius: 0 0 0 10px;

        svg {
          margin-top: 32px;
        }
      }

      .media-body {
        button {
          #app & {
            width: 187px;
            height: 48px;
            font-size: 13px;
            font-weight: $font-weight-bold;
            letter-spacing: 0.65px;
            line-height: 12px;
          }
        }
      }
    }

    .media-body {
      margin: 15px;

      .title-headline {
        height: auto;
      }

      .title-headline,
      .price {
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        color: $dark;
      }

      .info {
        font-weight: $font-weight-bold;
        font-size: 9px;
        line-height: 11px;
        letter-spacing: 0;
        color: #a0a0a0;
        margin-bottom: 0;
      }

      .price {
        line-height: 48px;
        &::before {
          display: inline-block;
          padding: 0px 5px 0px 0px;
          vertical-align: -2px;
          content: url('../../assets/img/icon-yellow-plus-sign.png');
        }
      }
    }
  }
}
</style>
