<template>
  <div class="booking-progress">
    <b-progress :value="progressValue" :max="progressMax" height="7px" />
    <div class="d-flex justify-content-between">
      <span>{{ getTimeString(time.start) }}</span>
      <span>{{ getTimeString(time.end) }}</span>
    </div>
  </div>
</template>

<script>
const moment = require('moment')
moment.locale('fr-FR')

export default {
  name: 'booking-progress',
  props: {
    time: { type: Object, required: true }
  },
  data() {
    return {
      moment: moment
    }
  },
  computed: {
    progressValue() {
      return Math.max(0, Date.now() - this.time.start.getTime())
    },
    progressMax() {
      return this.time.end.getTime() - this.time.start.getTime()
    }
  },
  methods: {
    getTimeString(date) {
      return moment(date).format('HH[h]mm')
    },
    delay() {
      return Date.now() < this.time.start.getTime()
        ? moment(this.time.start).fromNow() + ' au point de recharge'
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.booking-progress {
  .progress {
    background-color: #fdfdfd;
  }
}
</style>
<style lang="scss">
@import '@/styles.scss';

.booking-progress {
  font-weight: $font-weight-bold;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0;
  color: #a0a0a0;

  .progress {
    margin: 3px 0;

    .progress-bar {
      background-color: $dark;
    }
  }
}
</style>
